@import '~primeicons/primeicons.css';
@import '~@timebox/custom-primeng/resources/primeng.min.css';
@import 'primeflex/primeflex.scss';

// @import 'src/assets/themes/mira/default.css';
@import 'src/assets/themes/mira/theme.css';

$poppins-font-path: '/assets/fonts/Poppins';
$font-family: 'Poppins';
$font-weight-normal: 400;
$font-weight-bold: 700;
$font-weight-medium: 500;

$font-style-normal: normal;
$font-style-italic: italic;
$font-style-medium: medium;

@font-face {
    font-family: $font-family;
    font-weight: $font-weight-normal;
    font-style: $font-style-normal;
    src:
        local('Poppins Regular'),
        url('#{$poppins-font-path}/Poppins-Regular.ttf') format('truetype');
}

@font-face {
    font-family: $font-family;
    font-weight: $font-weight-bold;
    font-style: $font-style-normal;
    src:
        local('Poppins Bold'),
        url('#{$poppins-font-path}/Poppins-Bold.ttf') format('truetype');
}

@font-face {
    font-family: $font-family;
    font-weight: $font-weight-medium;
    font-style: $font-style-medium;
    src:
        local('Poppins Medium'),
        url('#{$poppins-font-path}/Poppins-Medium.ttf') format('truetype');
}

@font-face {
    font-family: $font-family;
    font-weight: $font-weight-bold;
    font-style: $font-style-italic;
    src:
        local('Poppins Bold Italic'),
        url('#{$poppins-font-path}/Poppins-BoldItalic.ttf') format('truetype');
}

html {
    font-family: 'Poppins', sans-serif !important;
    background-color: var(--surface-a);
}

html,
body {
    height: 100%;
}
body {
    margin: 0;
}

h1 {
    font-weight: 500;
    font-size: 3rem;
    color: var(--text-color); // Verwende die aus dem Cookie ausgelesene Farbe
}

p {
    color: var(--text-color);
}

.error-text {
    color: var(--text-color-secondary);
}

::-webkit-scrollbar {
    width: 0.75rem;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0.125rem var(--surface-b);
    border-radius: 0.5rem;
}

::-webkit-scrollbar-thumb {
    background: var(
        --primary-color
    ); // Verwende die aus dem Cookie ausgelesene Farbe
    border-radius: 0.5rem;
}

::-webkit-scrollbar-thumb:hover {
    background: var(--surface-b);
}

input[type='number'] {
    -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.p-progress-spinner-circle {
  animation: p-progress-spinner-dash 1.5s ease-in-out infinite;
  stroke: var(--text-color-secondary);
}
